dmx.Component('google-places-search', {
  
  initialData: {
    results: [],
    hasMore: false,
    status: '',
  },

  attributes: {
    map: {
      type: String,
      default: null,
    },

    fields: {
      type: [Array, String],
      default: ['place_id', 'business_status', 'formatted_address', 'geometry', 'icon', 'name', 'type'],
    },

    showOnMap: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    findPlaceFromQuery (params) {
      this._findPlaceFrom('query', params);
    },

    findPlaceFromPhoneNumber (params) {
      this._findPlaceFrom('phoneNumber', params);
    },

    nearby (params) {
      this._search(params, true);
    },

    search (params) {
      this._search(params);
    },

    getMore () {
      this._getMore();
    },
  },

  init () {
    this._markers = [];

    if (this.props.map) {
      requestAnimationFrame(() => {
        const node = document.getElementById(this.props.map);
        this._cluster = node && node.dmxComponent && node.dmxComponent._cluster;
        this._map = node && node.dmxComponent && node.dmxComponent._map;
      });
    }
  },

  performUpdate (updatedProps) {
    if (updatedProps.has('map')) {
      if (this.props.map) {
        const node = document.getElementById(this.props.map);
        this._cluster = node && node.dmxComponent && node.dmxComponent._cluster;
        this._map = node && node.dmxComponent && node.dmxComponent._map;
      } else {
        this._cluster = null;
        this._map = null;
      }
    }
  },

  destroy () {
    // cleanup
  },

  _clearMarkers () {
    if (this._cluster) {
      this._cluster.clearMarkers();
    }

    this._markers = this._markers.filter(marker => {
      google.maps.event.clearInstanceListeners(marker);
      marker.setMap(null);
      return false;
    });
  },

  _findPlaceFrom (prop, params) {
    this._clearMarkers();

    const fields = params.fields || this.props.fields;
    const request = {
      [prop]: params[prop],
      fields: Array.isArray(fields) ? fields : fields.split(/\s*,\s*/),
    };

    if (params.bindBounds && this._map) {
      request.locationBias = this._map.getBounds();
    } else {
      if (params.latitude && params.longitude) {
        if (params.radius) {
          request.locationBias = {
            center: { lat: +params.latitude, lng: +params.longitude },
            radius: +params.radius,
          };
        } else {
          request.locationBias = { lat: +params.latitude, lng: +params.longitude };
        }
      }
    }

    const service = new google.maps.places.PlacesService(this._map);
    service['findPlaceFrom' + this._ucase(prop)](request, this._results.bind(this));
  },

  _search (params, nearby = false) {
    this._clearMarkers();

    const prop = nearby ? 'type' : 'query';
    const request = {
      [prop]: params[prop],
    };

    if (params.latitude != null && params.longitude != null) {
      request.location = { lat: +params.latitude, lng: +params.longitude };
      request.radius = +params.radius || 500;
    } else if (this._map) {
      request.bounds = this._map.getBounds();
    }

    if (params.keyword) {
      request.keyword = params.keyword;
    }

    if (params.name) {
      request.name = params.name;
    }

    if (params.openNow) {
      request.openNow = params.openNow;
    }

    const service = new google.maps.places.PlacesService(this._map);
    const prefix = nearby ? 'nearby' : 'text';
    service[prefix + 'Search'](request, this._results.bind(this));
  },

  _getMore () {
    if (this._pagination && this._pagination.hasNextPage) {
      this._pagination.nextPage();
    }
  },

  _results (results, status, pagination) {
    this.set('status', status);
    this.set('hasMore', !!(pagination && pagination.hasNextPage));

    this._pagination = pagination;

    if (status == 'OK') {
      this.set('results', results.map(place => ({
        placeId: place.place_id,
        address: place.formatted_address,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        icon: place.icon,
        name: place.name,
        types: place.types,
      })));

      if (this.props.showOnMap) {
        // create markers on map
        results.forEach(place => {
          const image = {
            url: place.icon,
            size: new google.maps.Size(71, 71),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(17, 34),
            scaledSize: new google.maps.Size(25, 25),
          };

          const marker = new google.maps.Marker({
            //map: this._map,
            icon: image,
            title: place.name,
            position: place.geometry.location,
          });

          if (this._cluster) {
            this._cluster.addMarker(marker);
          } else {
            marker.setMap(this._map);
          }

          this._markers.push(marker);
        });
      }
    } else {
      console.warn(`Places search failed: ${status}`);
    }
  },

  _ucase (str) {
    return str[0].toUpperCase() + str.slice(1);
  },

});
