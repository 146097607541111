dmx.Component('google-autocomplete', {

  extends: 'form-element',

  initialData: {
    placeId: null,
    address: null,
    phone: null,
    phone2: null,
    latitude: null,
    longitude: null,
    icon: null,
    name: null,
    priceLevel: null,
    rating: null,
    types: null,
    url: null,
    utcOffset: null,
    vicinity: null,
    website: null,
  },

  attributes: {
    map: {
      type: String,
      default: null,
    },

    country: {
      type: String,
      default: null,
    },

    types: {
      type: String,
      default: null,
    },

    strictBounds: {
      type: Boolean,
      default: false,
    },

    moveMap: {
      type: Boolean,
      default: false,
    },
  },

  render (node) {
    this._autocomplete = new google.maps.places.Autocomplete(node, {
      strictBounds: this.props.strictBounds,
      types: this.props.types ? this.props.types.split(/\s*,\s*/) : [],
    });
    
    if (this.props.map) {
      requestAnimationFrame(() => {
        this._map = this._getMap();
        this._autocomplete.bindTo('bounds', this._map);
      });
    }

    if (this.props.country) {
      this._autocomplete.setComponentRestrictions({ country: this.props.country.split(/\s*,\s*/) });
    }

    this._placeChangedHandler = this._placeChangedHandler.bind(this);
    this._autocomplete.addListener('place_changed', this._placeChangedHandler);

    this.set('value', this.props.value);
    this.set('disabled', this.props.disabled);
  },

  performUpdate (updatedProps) {
    if (updatedProps.has('map')) {
      this._map = this._getMap();
      this._autocomplete.bindTo('bounds', this._map);
    }

    if (updatedProps.has('strictBounds')) {
      this._autocomplete.setOptions({ strictBounds: this.props.strictBounds });
    }

    if (updatedProps.has('types')) {
      this._autocomplete.setTypes(this.props.types);
    }

    if (updatedProps.has('country')) {
      this._autocomplete.setComponentRestrictions({ country: this.props.country ? this.props.country.split(/\s*,\s*/) : [] });
    }

    if (updatedProps.has('value')) {
      this._setValue(this.props.value, true);
    }

    if (updatedProps.has('disabled')) {
      this._disable(this.props.disabled);
    }
  },

  destroy () {
    // cleanup
  },

  _getMap () {
    if (this.props.map) {
      const node = document.getElementById(this.props.map);
      return node && node.dmxComponent && node.dmxComponent._map;
    }
  },

  _placeChangedHandler () {
    const place = this._autocomplete.getPlace();
    if (!place.place_id) return;

    this.set('value', this.$node.value);
    this.set({
      placeId: place.place_id,
      address: place.formatted_address,
      phone: place.formatted_phone_number,
      phone2: place.international_phone_number,
      latitude: place.geometry && place.geometry.location.lat(),
      longitude: place.geometry && place.geometry.location.lng(),
      attributions: place.html_attributions,
      icon: place.icon,
      name: place.name,
      isOpen: place.opening_hours && place.opening_hours.isOpen(),
      priceLevel: place.price_level,
      rating: place.rating,
      types: place.types,
      url: place.url,
      userRatingsTotal: place.user_ratings_total,
      utcOffset: place.utc_offset_minutes,
      vicinity: place.vicinity,
      website: place.website,
      adrAddress: place.adr_address,
      components: Array.isArray(place.address_components) ? place.address_components.reduce(function (output, component) {
        component.types.forEach(function (type) {
          output[type.replace(/_(\w)/g, function (m, c) {
            return c.toUpperCase();
          })] = {
            long: component.long_name,
            short: component.short_name,
          };
        });
        return output;
      }, {}) : null,
    });

    if (this.props.moveMap && this._map) {
      if (place.geometry.viewport) {
        this._map.fitBounds(place.geometry.viewport);
      } else {
        this._map.setCenter(place.geometry.location);
        this._map.setZoom(17);
      }
    }

    setTimeout(this.dispatchEvent.bind(this, 'updated'), 100);
  },

});
